<template>
  <div>

    <div class="grid grid-cols-1 md:grid-cols-2 mb-6">
      <div class="col-span-1 flex items-center">
        <h2 class="text-2xl font-bold">Bills Transactions</h2>
      </div>
      <div class="col-span-1">
        <search-form v-model="bills.query" @submit="event => $refs.table.loadAjaxData()" />
      </div>
    </div>

    <datatable
      :index="true"
      :reverse-index="true"
      :ajax="true"
      :ajaxPagination="true"
      :onClick="userProfile"
      :url="bills.url"
      :columns="bills.columns"
      :query="bills.query"
      ref="table"
    />

  </div>
</template>

<script>
  export default {
    data() {
      return {
        bills: this.$options.resource(null, {
          url: `${this.$baseurl}/admin/personal/bills/transactions`,
          query: '',
          columns: [
            {
              th: 'User',
              name: 'user',
              render: (transaction, user) => `${user?.name} ${user?.last_name || ''}`
            },
            {
              th: 'Company',
              name: 'company',
              render: (transaction) => transaction.user?.company?.name || '---'
            },
            {
              th: 'Transaction',
              name: 'type',
              render: (transaction, type) => this.$options.filters.sentenceCase(this.$options.filters.fromSlug(type))
            },
            {
              th: 'Reference',
              name: 'request_ref',
              render: (transaction, request_ref) => request_ref
            },
            {
              th: 'Amount',
              name: 'amount',
            },
            {
              th: 'Payment Method',
              name: 'payment_method',
              render: (transaction, payment_method) => this.$options.filters.sentenceCase(this.$options.filters.fromSlug(payment_method))
            },
            {
              th: 'Status',
              name: 'status',
              render: (transaction, status) => {
                switch (status) {
                  case 'success':
                    return '<div class="badge badge-green-soft-outline">Success</div>'
                  default:
                    return status;
                }
              }
            },
            {
              th: 'Date',
              name: 'created_at',
              render: (transaction, date) => this.$options.filters.dateFormat(date, 'D, dd M Y, h:ia')
            },
          ],
        })
      }
    }
  }
</script>